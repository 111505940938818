import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LaravelResourceResponse } from '../../../../../../../../../_base-shared/contracts/laravel-response.interface';
import { Case } from '../../../../../../../../../_base-shared/models/Case/Case';
import { Note, NoteType } from '../../../../../../../../../_base-shared/models/Note';
import { UploadService } from '../../../../../../../../../_base-shared/services/upload.service';
import { CaseService } from '../../../../case.service';
import { NoteService } from '../../../../note.service';
import { User } from '../../../../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from "../../../../../../_shared/services/main-global-event.service";

@Component({
  selector:    'app-case-notes',
  templateUrl: './case-notes.component.html',
  styles:      [
    `
      .scroll-notes {
        max-height : 500px;
        overflow   : auto;
      }
    `],
})
export class CaseNotesComponent implements OnInit, OnDestroy {
  @Input() case: Case;
  @Input() noteType: 'note' | 'payment' | 'draft' | 'distribution';
  public notes: Array<Note>                  = [];
  public isLoading                           = 0;
  public selectedNoteId: number;
  public editorType: 'create' | 'edit' | null;
  public isSubmitting                        = false;
  public isNoteSticky: boolean;
  public form: UntypedFormGroup;
  public authUser: User;
  public quillModules                        = {
    imageUploader: {
      upload: (file) => this.uploadFile(file),
    },
  };
  private formObservable: Observable<LaravelResourceResponse<Note>>;
  private subscriptions: Array<Subscription> = [];

  constructor(
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService,
    private uploadService: UploadService,
    private caseService: CaseService,
    private noteService: NoteService,
    private globalEventsService: MainGlobalEventService
  ) {
  }

  ngOnInit(): void {
    this.fetchNotes();
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public submitForm(form: UntypedFormGroup, saveType: NoteType) {
    if (form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    const noteData = form.value;
    noteData.type  = saveType;

    this.isSubmitting   = true;
    this.formObservable = this.editorType === 'edit' ?
      this.noteService.update(this.selectedNoteId, noteData, ['user']) :
      this.noteService.store(this.case.id, noteData, ['user']);

    this.subscriptions.push(
      this.formObservable.pipe(finalize(() => this.isSubmitting = false)).subscribe(result => {
        const resultNote = result.data;

        if (this.editorType === 'edit') {
          const noteIndex = this.notes.findIndex(fNote => fNote.id === this.selectedNoteId);
          if (noteIndex > -1) {
            this.notes.splice(noteIndex, 1, resultNote);
          } else {
            this.notes.unshift(result.data); // todoish: add local sorting
          }
        } else {
          this.notes.unshift(result.data); // todoish: add local sorting
        }
        this.fetchNotes();
        this.editorType = null;
        this.form       = null;
      }),
    );
  }

  public deleteNote(noteId: number) {
    this.noteService.delete(noteId).pipe(finalize(() => this.isSubmitting = false)).subscribe(
      res => {
        const noteIndex = this.notes.findIndex(fNote => fNote.id === noteId);
        if (noteIndex > -1) {
          this.notes.splice(noteIndex, 1);
        }
        this.toastr.success(this.translate.instant('CASES.single.general.relation_list.notes.result.note_deleted'));
      },
      err => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
    );
  }

  public addNewNote() {
    const defaultNote = this.buildDefaultNote();
    this.buildForm(defaultNote);
  }

  public editNote(note: Note) {
    this.selectedNoteId = note.id;
    this.form           = null;
    this.buildForm(note);
  }

  public toogleSticky(note: Note) {
    this.isNoteSticky = ! note.sticky;
    this.noteService.unsticky(note.case_id, note.id, this.isNoteSticky).subscribe(
      res => {
        this.fetchNotes();
        this.toastr.success(this.translate.instant('CASES.single.general.relation_list.notes.successfully_updated_note'));
      },
      err => this.toastr.error(this.translate.instant('SHARED.went-wrong')),
    );
  }

  public cancelNote() {
    this.editorType     = null;
    this.form           = null;
    this.selectedNoteId = null;
  }

  public clearDatePicker() {
    this.form.patchValue({contacted_at: null});
  }

  private buildForm(note: Note) {
    this.editorType = note.id ? 'edit' : 'create';
    this.form       = this.fb.group({
      type:                  [note.type, [Validators.required]],
      note:                  [note.note, [Validators.required]],
      contacted_at:          [note.contacted_at ? new Date(note.contacted_at) : null],
      customer_contact_type: [note.customer_contact_type],
    });
  }

  private fetchNotes() {
    this.isLoading++;
    this.noteService.index(this.case.id, this.noteType, ['user']).pipe(finalize(() => this.isLoading--)).subscribe(
      res => this.notes = res.data,
      err => console.error(err),
    );
  }

  private buildDefaultNote(): Note {
    const note = new Note();
    note.type  = this.noteType;

    return note;
  }

  private uploadFile(file: any) {
    return this.uploadService.quillImgUpload(file);
  }
}
