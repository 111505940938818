import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';
import { AppJob } from '../../../../../../_base-shared/models/App/AppJob';
import { User } from '../../../../../../_base-shared/models/User/User';
import { MainGlobalEventService } from '../../../_shared/services/main-global-event.service';
import { ReportService } from '../report.service';
import { RunOffExportComponent } from '../run-off-export/run-off-export.component';
import { PaymentReceivedModalComponent } from './payment-received-modal/payment-received-modal.component';
import { SalesReportModalComponent } from './sales-report-modal/sales-report-modal.component';

@Component({
  selector:    'app-financial-report-list',
  templateUrl: './financial-report-list.component.html',
  styles:      [],
})
export class FinancialReportListComponent implements OnInit {
  private authUser: User;
  public appJobs: Array<AppJob> = [];
  public isLoadingJobs          = 0;
  public generatingBreakageReport: boolean;
  public displayedColumns: string[] = [
    'name',
    'description',
    'actions',
  ];
  public reportOptions: Array<any> = [
        {
            name:        'Payment Received',
            description: 'Generate Payment Received Report',
            action:      this.paymentReceivedModal.bind(this),
            disabled:    false,
        },
        {
            name:        'Loan Book Report',
            description: 'Generate Loan Book Report',
            action:      this.activeLoanReportModal.bind(this),
            disabled:    false,
        },
        {
            name:        'NovoMoney Summary',
            description: 'Generate NovoMoney Summary Report',
            action:      this.novoMoneySummaryReport.bind(this),
            disabled:    false,
        }
  ];

  constructor(private toastr: ToastrService,
              private globalEventsService: MainGlobalEventService,
              private reportService: ReportService,
              public dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.globalEventsService.authUser$.subscribe(user => this.authUser = user);

    this.fetchQueuedJobs();
  }

  public generateBreakageReport() {
    const requestData             = {
      user_id: this.authUser.id,
    };
    this.generatingBreakageReport = true;
    this.reportService.generateBreakageReport(requestData)
        .pipe(finalize(() => this.generatingBreakageReport = false))
        .subscribe(
            result => {
              this.toastr.success(result.message);
              this.fetchQueuedJobs();
            },
        );
  }

  public fetchQueuedJobs() {
    this.isLoadingJobs++;
    this.reportService.indexQueuedExports({queue: 'export'}).pipe(finalize(() => this.isLoadingJobs--))
        .subscribe(
            result => {
                this.toastr.success(result.message ?? 'Queued jobs fetched successfully');
              this.appJobs = result.data;
            },
        );
  }

  public paymentReceivedModal($event) {
    $event.stopPropagation();
    $event.preventDefault();

    this.dialog.open(PaymentReceivedModalComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '280px',
    });
  }

  public activeLoanReportModal($event) {
    $event.stopPropagation();
    $event.preventDefault();


    this.reportService.generateActiveLoanReport().subscribe(
        result => {
          this.toastr.success(result.message);
        },
    );
  }

  public novoMoneySummaryReport($event) {
    $event.stopPropagation();
    $event.preventDefault();

    this.reportService.novoMoneySummaryReport().subscribe(
        result => {
          this.toastr.success(result.message);
        }
    );
  }

  public salesReportModal($event) {
    $event.stopPropagation();
    $event.preventDefault();

    this.dialog.open(SalesReportModalComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '280px',
    });
  }

  public runOffReportModal() {
    this.dialog.open(RunOffExportComponent, {
      width:     '40%',
      autoFocus: false,
      minHeight: '280px',
    });
  }
}
